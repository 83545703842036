import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import { Button, illustrations } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import { ExpatLt } from '../../models';
import styles from './AddDependents.module.scss';

export const AddDependents = ({
  onSubmitValue,
}: QuestionnaireFormProps<ExpatLt>) => {
  const { t } = useSafeTranslation();
  return (
    <div
      className={`p-container d-flex fd-column ai-start jc-center wmx10 ${styles.container}`}
    >
      <img src={illustrations.dependents} alt="family icon" width={65} />
      <h1 className="p-h1 mt16 mb8 ta-center">
        {t(
          'expatLt.qnr.addFamilyMembers.title',
          'Who would you like to cover?'
        )}
      </h1>
      <div className="p-p ai-start">
        {t(
          'expatLt.qnr.addFamilyMembers.description',
          'After purchasing this policy, you can buy and manage additional policies for your loved ones.'
        )}
      </div>
      <div className="d-flex fd-row gap16 mt24 ai-center jc-center">
        <Button
          onClick={() => onSubmitValue(false)}
          data-cy="dependents-cover-no"
        >
          {t('expatLt.qnr.addFamilyMembers.cta.noLabel', 'Just myself')}
        </Button>
        <Button
          onClick={() => onSubmitValue(true)}
          variant="filledWhite"
          data-cy="dependents-cover-yes"
        >
          {t(
            'expatLt.qnr.addFamilyMembers.cta.yesLabel',
            'Myself and my family'
          )}
        </Button>
      </div>
    </div>
  );
};
