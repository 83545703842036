import { getTrackingObject } from '@getpopsure/tracker';
import * as Sentry from '@sentry/react';
import { createAccountV2 } from 'actions/account';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { generateCheckoutDocuments } from 'features/checkoutDocuments/actions';
import { APIResponseError } from 'models/error';
import { AppState } from 'reducers';
import { GenericQuestionnaireAction } from 'reducers/genericQuestionnaire';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { TFunction } from 'shared/i18n/utils';
import { CreateQuoteDispatch } from 'SignupQuestionnaire/actions/account';

import {
  CreateQuoteReturnType,
  ExpatLtDependent,
  Quote,
  Quotes,
  SubmittableExpatLtDependentQuestionnaire,
  SubmittableExpatLtQuestionnaire,
  ZQuote,
  ZSubmittableExpatLtDependentQuestionnaire,
  ZSubmittableExpatLtQuestionnaire,
} from '../models';

export type ExpatLtDispatch<T extends Action = never> = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  GenericQuestionnaireAction<'expatLt'> | T
>;

export const storeDependentGenericQuestionnaireAnswer =
  (answer: Partial<ExpatLtDependent>) =>
  (dispatch: ExpatLtDispatch, getState: () => AppState) => {
    const state = getState();
    const dependent = state.genericQuestionnaire.expatLt?.dependent;

    dispatch(
      storeGenericQuestionnaireAnswer('expatLt', {
        dependent: { ...dependent, ...answer },
      })
    );
  };

export const removeDependentGenericQuestionnaireAnswer =
  (questionIds: Array<keyof ExpatLtDependent>) =>
  (dispatch: ExpatLtDispatch, getState: () => AppState) => {
    const dependents = getGenericQuestionnaire(getState()).expatLt?.dependent;

    const nextState: Record<string, unknown> = {
      ...dependents,
    };
    for (const id of questionIds) {
      if (nextState[id] !== undefined) {
        delete nextState[id];
      }
    }

    dispatch(
      storeGenericQuestionnaireAnswer('expatLt', {
        dependent: nextState,
      })
    );
  };

export const createExpatLtQuote = (answers: Quote) => {
  return endpoint.network.post<Quotes>('/signups/expat-lt/quotes', answers);
};

const createExpatLtQuestionnaire = async (
  answers:
    | SubmittableExpatLtQuestionnaire
    | SubmittableExpatLtDependentQuestionnaire,
  questionnaireType: 'INCOMING_LT' | 'INCOMING_LT_DEPENDENT'
) => {
  const source = getTrackingObject();
  return endpoint.network.post<{
    id: string;
    answers: SubmittableExpatLtQuestionnaire;
  }>('/questionnaires/', {
    answers,
    questionnaireType,
    metadata: source,
  });
};

export const createPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expatLt'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'expatLt'>,
    getState: () => AppState
  ) => {
    const questionnaire = getGenericQuestionnaire(getState()).expatLt || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[INCOMING_LT] Quote not defined`);
      }

      const quoteValidation = ZQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[INCOMING_LT - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult =
        ZSubmittableExpatLtQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[INCOMING_LT - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const { data: selectedQuote } = await createExpatLtQuote(validatedQuote);

      if (!selectedQuote) {
        throw new Error('[INCOMING_LT] Selected quote not found');
      }

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        email,
        name: { firstName, lastName },
      } = validatedAnswers.personalInfo;

      // check if the account exists and create a new account if needed
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      if (!userExists) {
        await dispatch(
          createAccountV2({
            firstName,
            lastName,
            email,
          })
        );
      }

      const {
        data: { id: questionnaireId },
      } = await createExpatLtQuestionnaire(validatedAnswers, 'INCOMING_LT');
      dispatch(
        storeGenericQuestionnaireAnswer('expatLt', {
          quoteId: selectedQuote.id,
          questionnaireId,
          quote: { ...questionnaire.quote, id: selectedQuote.id },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        selectedQuote?.id ?? '',
        'INCOMING_LT',
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );

      Sentry.captureException(error, {
        tags: {
          feature: 'INCOMING_LT Review',
        },
        extra: {
          description: 'Failed to submit',
        },
      });
      return { status: 'ERROR' };
    }
  };

export const createDependentPostQuote =
  (
    t: TFunction
  ): ThunkAction<
    Promise<CreateQuoteReturnType>,
    AppState,
    Record<string, unknown>,
    GenericQuestionnaireAction<'expatLt'> | RequestAction
  > =>
  async (
    dispatch: CreateQuoteDispatch<'expatLt'>,
    getState: () => AppState
  ) => {
    const questionnaire =
      getGenericQuestionnaire(getState()).expatLt?.dependent || {};

    try {
      if (!questionnaire.quote) {
        throw new Error(`[INCOMING_LT] Quote not defined`);
      }

      const quoteValidation = ZQuote.safeParse(questionnaire.quote);

      if (!quoteValidation.success) {
        throw new Error(
          `[INCOMING_LT_DEPENDENT - Submission] Questionnaire quote errors: ${quoteValidation.error.toString()}`
        );
      }

      const validatedQuote = quoteValidation.data;

      const validationResult =
        ZSubmittableExpatLtDependentQuestionnaire.safeParse(questionnaire);

      if (!validationResult.success) {
        throw new Error(
          `[INCOMING_LT_DEPENDENT - Submission] Questionnaire answer validation errors: ${validationResult.error.toString()}`
        );
      }

      const validatedAnswers = validationResult.data;

      const { data: selectedQuote } = await createExpatLtQuote(validatedQuote);

      if (!selectedQuote) {
        throw new Error('[INCOMING_LT_DEPENDENT] Selected quote not found');
      }

      dispatch(setRequestInProcess(true, 'CREATE_POST_QUOTE_SUBMIT_INFO'));

      const {
        data: { id: questionnaireId },
      } = await createExpatLtQuestionnaire(
        validatedAnswers,
        'INCOMING_LT_DEPENDENT'
      );
      dispatch(
        storeDependentGenericQuestionnaireAnswer({
          quoteId: selectedQuote.id,
          questionnaireId,
          quote: { ...questionnaire.quote, id: selectedQuote.id },
        })
      );

      await generateCheckoutDocuments(
        questionnaireId,
        selectedQuote.id ?? '',
        'INCOMING_LT',
        t
      );

      dispatch(setRequestInProcess(false, 'CREATE_POST_QUOTE_SUBMIT_INFO'));
      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'CREATE_POST_QUOTE_SUBMIT_INFO'
        )
      );

      Sentry.captureException(error, {
        tags: {
          feature: 'INCOMING_LT Review',
        },
        extra: {
          description: 'Failed to submit',
        },
      });

      return { status: 'ERROR' };
    }
  };
