import { Bike } from 'features/bike/models';
import { Dental } from 'features/dental/models';
import { Disability } from 'features/disability/models';
import { DisabilityV2 } from 'features/disabilityV2/models';
import { Dog } from 'features/dogLiability/models';
import { ExpatQuestionnaire } from 'features/expat/models';
import { ExpatEu } from 'features/expatEu/models';
import { ExpatLt } from 'features/expatLt/models';
import { ExpatSpain } from 'features/expatSpain/models';
import { Legal } from 'features/legal/models';
import { Life } from 'features/life/models';
import { Pension } from 'features/pension/models';
import { PetHealth } from 'features/petHealth/models';
import { TravelHealthV1 } from 'features/travelHealthV1/models';
import type {
  Liability,
  UserRegistrationInfo,
} from 'models/genericQuestionnaire';
import { Reducer } from 'redux';
import { WithRequiredProperty } from 'shared/models/util';

export interface GenericQuestionnaireState {
  userCreation?: Partial<UserRegistrationInfo>;
  legal?: Partial<Legal>;
  dentalV2?: Partial<Dental>;
  expat?: Partial<ExpatQuestionnaire>;
  life?: Partial<Life>;
  liability?: Partial<Liability>;
  dog?: Partial<Dog>;
  petHealth?: Partial<PetHealth>;
  lifeV2?: Partial<Life>;
  travelHealthV1?: Partial<TravelHealthV1>;
  expatSpain?: Partial<ExpatSpain>;
  bike?: Partial<Bike>;
  expatEu?: Partial<ExpatEu>;
  expatLt?: Partial<ExpatLt>;
  disability?: Partial<Disability>;
  disabilityV2?: Partial<DisabilityV2>;
  pension?: Partial<Pension>;
}

// questionIds had a type of never[] since the keys of GenericQuestionnaireState were optional.
// To be able to correctly type questionIds we need to make the property of key T required by using
// WithRequiredProperty<GenericQuestionnaireState, T> to receive a version of GenericQuestionnaireState where this property is not optional.
export type GenericQuestionnaireAction<
  T extends keyof GenericQuestionnaireState
> =
  | {
      type:
        | 'GENERIC_QUESTIONNAIRE_ANSWERED_QUESTION'
        | 'FLUSH_GENERIC_QUESTIONNAIRE'
        | 'SET_CURRENT_GENERIC_QUESTIONNAIRE';
      answer?: GenericQuestionnaireState[T];
      questionnaire: T;
    }
  | {
      type: 'REMOVE_ANSWER';
      questionnaire: T;
      questionIds: Array<
        keyof WithRequiredProperty<GenericQuestionnaireState, T>[T]
      >;
    };

export const defaultState: Record<string, unknown> = {};

export const actionHasNewQuestionnaireId = (
  action: GenericQuestionnaireAction<keyof GenericQuestionnaireState>
): boolean => {
  if (
    typeof action !== 'object' ||
    !('answer' in action) ||
    !action?.answer ||
    typeof action.answer !== 'object' ||
    !('quote' in action.answer) ||
    !action.answer?.quote
  ) {
    return false;
  }

  return (
    typeof action.answer.quote === 'object' &&
    'questionnaireId' in action.answer.quote &&
    !!action?.answer?.quote?.questionnaireId
  );
};

const genericQuestionnaire: Reducer<
  GenericQuestionnaireState,
  GenericQuestionnaireAction<keyof GenericQuestionnaireState>
> = (state = defaultState, action) => {
  switch (action.type) {
    case 'GENERIC_QUESTIONNAIRE_ANSWERED_QUESTION':
      return {
        ...state,
        [action.questionnaire]: {
          ...state[action.questionnaire],
          ...action.answer,
          ...(actionHasNewQuestionnaireId(action) && {
            hasDownloadedDocs: false,
          }),
        },
      };
    case 'FLUSH_GENERIC_QUESTIONNAIRE':
      return {
        ...state,
        [action.questionnaire]: {},
      };
    case 'SET_CURRENT_GENERIC_QUESTIONNAIRE':
      return {
        ...state,
        [action.questionnaire]: action.answer,
      };
    case 'REMOVE_ANSWER': {
      const newState: Record<string, unknown> = {
        ...state[action.questionnaire],
      };
      for (const id of action.questionIds) {
        if (newState[id] !== undefined) {
          delete newState[id];
        }
      }
      return {
        ...state,
        [action.questionnaire]: newState,
      };
    }
    default:
      return state;
  }
};

export default genericQuestionnaire;
