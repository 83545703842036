import { faq } from '@getpopsure/private-constants';
import { Button, illustrations } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { contentMapping } from 'features/expat/models';
import { QuotePlan } from 'features/expatLt/models';
import featherLogoIcon from 'icons/feather-icon.svg';
import { useSafeTranslation } from 'shared/i18n';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import styles from './Footer.module.scss';

interface QuoteFooterProps {
  bookACall: () => void;
  onSubmit: () => void;
  plan: QuotePlan;
  price: number;
}

export const QuoteFooter = ({
  bookACall,
  onSubmit,
  plan,
  price,
}: QuoteFooterProps) => {
  const { t } = useSafeTranslation();
  const shieldIcon =
    plan === 'BASIC' ? illustrations.shield1 : illustrations.shield3;

  return (
    <>
      <section className="py40 bg-primary-50">
        <div className="p-body d-flex fd-column ai-center">
          <img
            src={featherLogoIcon}
            alt="logo"
            className={classNames(styles.logoIcon, 'mx-auto')}
          />

          <div className="p--serif p-h1 ta-center mt24">
            {t('expatLt.qnr.quote.moreQuestions.title', 'Have more questions?')}
          </div>

          <div className="p-p ta-center wmx8 mt24">
            {t(
              'expatLt.qnr.quote.moreQuestions.content',
              'Reach out to us for more information or check out our website to get answers to common questions about expat health insurance.'
            )}
          </div>

          <div className="mt24 wmn3 d-flex jc-between ai-center">
            <button
              type="button"
              className="p-a ds-interactive-component"
              onClick={bookACall}
            >
              {t('expatLt.qnr.quote.moreQuestions.bookCall', 'Book a call')}
            </button>

            <div className={styles.divider} />

            <a
              className="p-a"
              href={faq.expatEuCollection}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('expatLt.qnr.quote.moreQuestions.faq', 'Visit our FAQ')}
            </a>
          </div>
        </div>
      </section>

      <footer
        className={classNames(
          'w100 d-flex jc-center ai-center',
          styles.fixedPriceBar
        )}
      >
        <div
          className={classNames(
            'p-body jc-between ai-center ws12',
            styles.desktopSticky
          )}
        >
          <div className="d-flex ai-end gap8">
            <img src={shieldIcon} alt="" />
            <h3 className="p-h3">
              {contentMapping[plan].name}{' '}
              {t('expatLt.qnr.quote.stickyFooter.title', 'plan')}
            </h3>
          </div>

          <div className="d-flex ai-center gap16">
            <p className="p--serif p-p--small tc-primary-500">
              <span className="p-h2 tc-primary-500">
                {englishFormattedEuroCurrency(price)}
              </span>
              {t(
                'expatLt.qnr.preQuote.quote.stickyFooter.price.perMonth',
                '/mo'
              )}
            </p>

            <div className={`d-flex ai-center gap16 ${styles.buttonContainer}`}>
              <Button
                onClick={onSubmit}
                type="button"
                className="ws3"
                data-cy="expat-lt-quote-continue"
              >
                {t(
                  'expatLt.qnr.preQuote.quote.continueButton.label',
                  'Continue'
                )}
              </Button>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'p-body ai-center jc-between gap16 w100',
            styles.mobileSticky
          )}
        >
          <div className="d-flex ai-center gap16">
            <img src={shieldIcon} alt="" />
            <div>
              <h3 className="p-h3">
                {contentMapping[plan].name}{' '}
                {t('expatLt.qnr.quote.stickyFooter.title', 'plan')}
              </h3>
              <p className="p--serif p-p--small tc-primary-500">
                <span className="p-h2 tc-primary-500">
                  {englishFormattedEuroCurrency(price)}
                </span>
                {t(
                  'expatLt.qnr.preQuote.quote.stickyFooter.price.perMonth',
                  '/mo'
                )}
              </p>
            </div>
          </div>

          <Button onClick={onSubmit} type="button" className="ws2">
            {t('expatLt.qnr.preQuote.quote.continueButton.label', 'Continue')}
          </Button>
        </div>
      </footer>
    </>
  );
};
