import { Region } from '@getpopsure/public-models';
import { Stripe } from '@stripe/stripe-js';
import {
  flushGenericQuestionnaire,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import {
  PaymentMethodsAction,
  RequestAction,
  UserAction,
} from 'constants/actions';
import { RequestType } from 'constants/requestTypes';
import {
  CheckoutPolicyRequestPayload,
  mapVerticalId,
} from 'features/checkout/models';
import { redirectSuccessfulCheckout } from 'features/checkout/utils';
import { storeGecDataObject } from 'features/googleEnhancedConversions';
import { mergePaymentMethods } from 'features/paymentMethods/paymentMethods.actions';
import { clearReferrerCode } from 'features/referralEngine/actions';
import { APIResponseError } from 'models/error';
import { InsuranceTypes } from 'models/insurances/types';
import { PaymentMethod } from 'models/paymentMethods';
import { AppState } from 'reducers';
import {
  GenericQuestionnaireAction,
  GenericQuestionnaireState,
} from 'reducers/genericQuestionnaire';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getGenericQuestionnaire } from 'selectors/genericQuestionnaire';
import endpoint from 'shared/api';
import { trackConversions } from 'shared/trackers/trackConversions';

import {
  confirmCheckoutSubscription,
  createCheckoutIdAndRetrieveCheckoutInfo,
  finalizeCheckout,
  processCheckoutSubscription,
} from '../api';

export type CheckoutDispatch = ThunkDispatch<
  AppState,
  Record<string, unknown>,
  | RequestAction
  | PaymentMethodsAction
  | UserAction
  | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
>;

export const updateCheckoutInfoAndPaymentMethods =
  (checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload) =>
  async (dispatch: CheckoutDispatch) => {
    const requestType: RequestType = 'CHECKOUT_UPDATE';
    const verticalId = mapVerticalId[checkoutPolicyRequestPayload.type];

    if (!verticalId) {
      throw new Error(
        `[Checkout] Vertical id not found while trying to update checkout info for questionnaire id: ${checkoutPolicyRequestPayload.policyInfo.questionnaireId}`
      );
    }

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data: checkoutInfo } =
        await createCheckoutIdAndRetrieveCheckoutInfo(
          endpoint.network,
          checkoutPolicyRequestPayload
        );

      const { email, name, address } = checkoutInfo.mainPolicy.policyHolder;

      storeGecDataObject({
        email,
        name,
        address,
      });

      const { data } = await endpoint.getPaymentMethods();
      dispatch(mergePaymentMethods(data));

      dispatch(
        storeGenericQuestionnaireAnswer(verticalId, {
          checkoutInfo,
        })
      );

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const updateCheckoutInfo =
  (checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload) =>
  async (dispatch: CheckoutDispatch) => {
    const requestType: RequestType = 'CHECKOUT_UPDATE';
    const verticalId = mapVerticalId[checkoutPolicyRequestPayload.type];

    if (!verticalId) {
      throw new Error(
        `[Checkout] Vertical id not found while trying to update checkout info for questionnaire id: ${checkoutPolicyRequestPayload.policyInfo.questionnaireId}`
      );
    }

    dispatch(setRequestInProcess(true, requestType));

    try {
      const { data: checkoutInfo } =
        await createCheckoutIdAndRetrieveCheckoutInfo(
          endpoint.network,
          checkoutPolicyRequestPayload
        );

      storeGecDataObject({
        email: checkoutInfo.mainPolicy.policyHolder.email,
        name: checkoutInfo.mainPolicy.policyHolder.name,
        address: checkoutInfo.mainPolicy.policyHolder.address,
      });

      dispatch(
        storeGenericQuestionnaireAnswer(verticalId, {
          checkoutInfo,
        })
      );

      dispatch(setRequestInProcess(false, requestType));
      return {
        checkoutInfo,
      };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));

      const axiosErrorMessage = (error as APIResponseError).response?.data
        ?.message;

      if (
        axiosErrorMessage &&
        typeof axiosErrorMessage === 'string' &&
        axiosErrorMessage.includes('start date')
      ) {
        return {
          error: 'BACKEND_START_DATE_VALIDATION_FAILED',
        };
      }
    }
  };

export const retrieveCheckoutInfoAndPaymentMethods =
  (checkoutPolicyRequestPayload: CheckoutPolicyRequestPayload) =>
  async (dispatch: CheckoutDispatch) => {
    const requestType: RequestType = 'CHECKOUT_REVIEW';
    const verticalId = mapVerticalId[checkoutPolicyRequestPayload.type];

    if (!verticalId) {
      throw new Error(
        `[Checkout] Vertical id not found while trying to fetch checkout info for questionnaire id: ${checkoutPolicyRequestPayload.policyInfo.questionnaireId}`
      );
    }

    dispatch(setRequestInProcess(true, requestType));

    try {
      await dispatch(
        updateCheckoutInfoAndPaymentMethods(checkoutPolicyRequestPayload)
      );

      const { data } = await endpoint.getPaymentMethods();
      dispatch(mergePaymentMethods(data));

      dispatch(setRequestInProcess(false, requestType));
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));
    }
  };

export const createCheckoutSubscription =
  ({
    verticalId,
    checkoutId,
    stripe,
    paymentMethod,
    returnPath,
  }: {
    verticalId: InsuranceTypes;
    checkoutId: string;
    stripe: Stripe;
    paymentMethod: PaymentMethod;
    returnPath?: string;
  }) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const referralCode = state.user?.userReferralInfo?.validReferrerCode;
    const verticalStateId = mapVerticalId[verticalId];
    const requestType: RequestType = 'CHECKOUT';

    if (!verticalStateId) {
      throw new Error(
        `[Checkout] Vertical state id not found for checkout id: ${checkoutId}`
      );
    }

    dispatch(setRequestInProcess(true, requestType));

    try {
      const {
        data: { actionRequired, clientSecret },
      } = await processCheckoutSubscription(
        endpoint.network,
        checkoutId,
        paymentMethod.id,
        referralCode,
        returnPath
      );

      if (actionRequired) {
        // TODO: the next line assumes there's only one type of action that
        //       can ever be required. This might change when we add more
        //       payment method types, at that point this code needs to be revisited.
        const { error } = await stripe.handleCardAction(clientSecret);

        if (error) {
          throw error;
        }

        await confirmCheckoutSubscription(
          endpoint.network,
          checkoutId,
          referralCode
        );
      }

      dispatch(setRequestInProcess(false, requestType));

      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));

      return { status: 'ERROR' };
    }
  };

export const finalize =
  ({
    checkoutId,
    paymentSucceeded,
  }: {
    checkoutId: string;
    paymentSucceeded: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const referralCode = state.user?.userReferralInfo?.validReferrerCode;
    const requestType: RequestType = 'CHECKOUT';

    dispatch(setRequestInProcess(true, requestType));

    try {
      await finalizeCheckout(
        endpoint.network,
        checkoutId,
        paymentSucceeded,
        referralCode
      );

      dispatch(setRequestInProcess(false, requestType));

      return { status: 'SUCCESS' };
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, requestType));

      return { status: 'ERROR' };
    }
  };

export const onSuccessfulCheckout =
  ({
    verticalId,
    policyId,
    localeId,
  }: {
    verticalId: InsuranceTypes;
    policyId?: string;
    localeId: Region;
  }) =>
  async (dispatch: CheckoutDispatch, getState: () => AppState) => {
    const verticalStateId = mapVerticalId[verticalId];
    const questionnaires = getGenericQuestionnaire(getState());

    // Track conversions
    if (policyId) {
      await trackConversions({
        verticalId,
        policyId,
        regionOfPurchase: localeId,
      });
    }

    // Cleanup
    dispatch(clearReferrerCode());

    if (verticalStateId) {
      dispatch(flushGenericQuestionnaire(verticalStateId));
    }

    // Redirect
    /**
     * EXPAT and INCOMING_ES use the mainPolicyId param to show a call to action
     * on the signup success screen.
     */

    const redirectPolicyId = getRedirectMainPolicyId(
      verticalId,
      questionnaires,
      policyId
    );

    if (verticalStateId) {
      redirectSuccessfulCheckout(verticalStateId, redirectPolicyId);
    }
  };

const getRedirectMainPolicyId = (
  verticalId: InsuranceTypes,
  questionnaires: GenericQuestionnaireState,
  policyId?: string
) => {
  if (verticalId === 'EXPAT_V2' || verticalId === 'INCOMING') {
    return questionnaires.expat?.addDependents ||
      questionnaires.expat?.dependent
      ? policyId
      : undefined;
  }

  if (verticalId === 'INCOMING_ES') {
    return questionnaires.expatSpain?.addFamilyMembers ? policyId : undefined;
  }

  if (verticalId === 'INCOMING_EU') {
    return questionnaires.expatEu?.addFamilyMembers ||
      questionnaires.expatEu?.dependent
      ? policyId
      : undefined;
  }

  if (verticalId === 'INCOMING_LT') {
    return questionnaires.expatLt?.addFamilyMembers ||
      questionnaires.expatLt?.dependent
      ? policyId
      : undefined;
  }

  return undefined;
};
