import { website } from '@getpopsure/private-constants';
import { Button, Card, illustrations } from '@popsure/dirty-swan';
import { useSafeTranslation } from 'shared/i18n';

import styles from './ConfirmUpgrade.module.scss';

type ConfirmUpgradeProps = {
  onSubmit: () => void;
};

export const ConfirmUpgrade = ({ onSubmit }: ConfirmUpgradeProps) => {
  const { t } = useSafeTranslation();

  return (
    <div className={`mb80 ${styles.animatedContainer}`}>
      <div className="p-body">
        <div>
          <div className="my16">
            <h1 className="p-h1">
              {t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.title',
                'Confirmation for upgrade'
              )}
            </h1>
            <p className="p-p mt16">
              {t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.description',
                'Here is some description'
              )}
            </p>
          </div>
          <div className="ws8 d-flex fd-column gap16">
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.payInvoice.title',
                'Info 1'
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.payInvoice.description',
                'Some description'
              )}
              icon={<img src={illustrations.creditCard} alt="credit card" />}
            />
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.copay.title',
                'Info 2'
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.copay.description',
                'Some description text'
              )}
              icon={<img src={illustrations.moneyIncome} alt="money" />}
            />
            <Card
              title={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.checkGuide.title',
                'Info 3'
              )}
              titleVariant="medium"
              description={t(
                'expatLt.qnr.confirmUpgrade.gettingStarted.checkGuide.description',
                'Some description text'
              )}
              icon={<img src={illustrations.checkDoc} alt="doc" />}
            />
          </div>
        </div>
        <div>
          <Button className="wmx5 wmn3 mt24 mr16 mb8" onClick={onSubmit}>
            {t('expatLt.qnr.confirmUpgrade.continue.button.text', 'Continue')}
          </Button>

          <a
            className="tc-primary-500 p-btn--secondary bg-white wmn3 mt24"
            href={website.support}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('expatLt.qnr.confirmUpgrade.contact.button.text', 'Contact us')}
          </a>
        </div>
      </div>
    </div>
  );
};
