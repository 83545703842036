import { website } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { TFunction } from 'shared/i18n/utils';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createPostQuote } from '../actions';
import { AddDependents } from '../components/AddDependents';
import { PolicyDetails } from '../components/policyDetails';
import { Quote } from '../components/Quote';
import { Signature } from '../components/Signature/Signature';
import {
  ExpatLt,
  ExpatLtGroupIds,
  genderMapping,
  occupationMapping,
} from '../models';

export const DATE_FORMAT = 'DD MMM YYYY';

export const ExpatLtComponents = {
  QUOTE_PAGE: Quote,
  ADD_DEPENDENTS: AddDependents,
  SIGNATURE: Signature,
} as const;

export type ExpatLtQuestionnaire = SignupQuestionnaireType<
  ExpatLt,
  ExpatLtGroupIds,
  typeof ExpatLtComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  region: string,
  regionId: Region,
  continueFromAgeIsBetween65And69Blocker: () => void,
  continueFromEmployedOccupationBlocker: () => void
): ExpatLtQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'expatLt.qnr.preQuote.intro.title',
        'Hey! Let’s get you a quote'
      ),
      subTitle: t(
        'expatLt.qnr.preQuote.intro.subTitle',
        'It takes less than a minute, no email needed'
      ),
      iconTitle: 'Long term expat health insurance',
    },
    screen: {
      continueButtonText: 'Get started',
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.preQuote.dateOfBirth.title',
        'When were you born?  '
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.dateOfBirth.validation.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'ageIs70AndAboveBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.preQuote.ageIs70AndAboveBlocker.title',
        'Unfortunately, we can’t sign you up'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.preQuote.ageIs70AndAboveBlocker.description',
        'Because you are over 70 years old, you aren’t eligible for long term expat health insurance. \n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatLt.qnr.preQuote.ageIs70AndAboveBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageIsBetween65And69Blocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.preQuote.ageIsBetween65And69Blocker.title',
        'Health screening needed'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.preQuote.ageIsBetween65And69Blocker.description',
        'As you are above 64 years of age, you will need to complete a health screening with a doctor to be able to sign-up for our expat health insurance.\n\nPlease get in touch with our insurance experts via the button below before continuing with your application.'
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromAgeIsBetween65And69Blocker,
          caption: t(
            'expatLt.qnr.preQuote.ageIsBetween65And69Blocker.button',
            'Continue application'
          ),
        },
        {
          type: 'href',
          href: website.support,
          variant: 'SECONDARY',
          caption: t(
            'expatLt.qnr.preQuote.ageIsBetween65And69Blocker.supportButton',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'occupation',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: occupationMapping(t),
    },
    screen: {
      question: t('expatLt.qnr.preQuote.occupation.title', {
        defaultValue: 'What’s your main occupation in {{region}}?',
        region,
      }),
    },
    groupId: 'preQuote',
  },
  {
    id: 'professionalAthleteOccupationBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.preQuote.professionalAthleteOccupationBlocker.title',
        'Unfortunately, we can’t sign you up'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.preQuote.professionalAthleteOccupationBlocker.description',
        'As a professional athlete, we are not able to cover you with our expat health insurance.\n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatLt.qnr.preQuote.professionalAthleteOccupationBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedOccupationBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.preQuote.employedOccupationBlocker.title',
        'Expat health insurance might not be right for you'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.preQuote.employedOccupationBlocker.description',
        {
          defaultValue:
            'Expat health insurance is typically not accepted by employers in {{ region }}. It does not offer full health insurance coverage or cover pre-existing conditions. \n\nIf you have questions, please feel free to contact us.',
          region,
        }
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromEmployedOccupationBlocker,
          caption: t(
            'expatLt.qnr.preQuote.employedOccupationBlocker.button',
            'Continue application'
          ),
        },
        {
          type: 'href',
          href: website.support,
          variant: 'SECONDARY',
          caption: t(
            'expatLt.qnr.preQuote.employedOccupationBlocker.supportButton',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'addFamilyMembers',
    required: false,
    type: 'ADD_DEPENDENTS',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTE_PAGE',
    props: {},
    screen: {
      question: '',
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'expatLt.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'expatLt.qnr.signup.signupIntro.subTitle',
        'Just 7 questions to your policy, it takes 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'expatLt.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'expatLt',
    },
    screen: {
      question: t(
        'expatLt.qnr.signup.postalCode.email.title',
        "What's your email address?"
      ),
      description: t(
        'expatLt.qnr.signup.postalCode.email.description',
        'This policy will be accessible in your Feather account.'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t('expatLt.qnr.signup.name.title', "What's your name?"),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t('expatLt.qnr.signup.gender.title', 'What is your gender?'),
    },
    groupId: 'signup',
  },
  {
    id: 'citizenship',
    required: true,
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.signup.citizenship.title',
        'Which countries do you hold a passport from?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'lastPermanentResidency',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: t('expatLt.qnr.signup.lastPermanentResidency.title', {
        defaultValue:
          'What was your country of residence before arriving in {{ region }}?',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    type: 'ADDRESS',
    props: {
      countryCode: regionId,
    },
    screen: {
      question: t('expatLt.qnr.signup.address.title', {
        defaultValue: 'What is your address in {{ region }}',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: -90,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.signup.startDate.errorMessage90DaysInFuture',
            'Coverage start date should not be more than 90 days in the future. Please adjust the start date.'
          ),
        },
      },
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.signup.startDate.dateIsInFuture',
            'Earliest start date is {{value}}. Please select another date.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.signup.legalGuardianName.title',
        "What's the name of your parent or legal guardian?"
      ),
      description: t(
        'expatLt.qnr.signup.legalGuardianName.description',
        "As you are under 18 years old, we need your parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianEmail',
    type: 'EMAIL_BASIC',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.signup.legalGuardianEmail.email.title',
        "What's their email address?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'signature',
    required: true,
    type: 'SIGNATURE',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t('expatLt.qnr.signup.review.plan.title', 'Plan'),
          value: {
            type: 'Strings',
            key: 'quote',
            keys: ['plan'],
          },
          path: 'quote',
        },
        {
          title: t('expatLt.qnr.signup.review.email.title', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.name.title',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.dateOfBirth.title',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t('expatLt.qnr.signup.review.gender.title', 'Gender'),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t('expatLt.qnr.signup.review.occupation.title', 'Occupation'),
          value: {
            type: 'String',
            valueMap: occupationMapping(t),
            key: 'occupation',
          },
          path: 'occupation',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.addFamilyMembers.title',
            'Who to cover?'
          ),
          value: {
            type: 'Boolean',
            valueMap: {
              true: t(
                'expatLt.qnr.signup.review.addFamilyMembers.valueMap.yes',
                'Myself and my family'
              ),
              false: t(
                'expatLt.qnr.signup.review.addFamilyMembers.valueMap.no',
                'Only myself'
              ),
            },
            key: 'addFamilyMembers',
          },
          path: 'addFamilyMembers',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.citizenship.title',
            'Citizenship'
          ),
          value: { type: 'Array', pick: ['name'], key: 'citizenship' },
          path: 'citizenship',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.lastPermanentResidency.title',
            'Last permanent residency'
          ),
          value: {
            type: 'Strings',
            key: 'lastPermanentResidency',
            keys: ['name'],
          },
          path: 'lastPermanentResidency',
        },
        {
          title: t('expatLt.qnr.signup.review.address.title', 'Address'),
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.startDate.title',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),
          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t(
            'expatLt.qnr.signup.review.legalGuardianEmail',
            "Parent / legal guardian's email"
          ),
          value: { type: 'String', key: 'legalGuardianEmail' },
          path: 'legalGuardianEmail',
        },
      ],
      verticalId: 'expatLt',
      insuranceType: 'INCOMING_LT',
      region: regionId,
      createPostQuote: () => createPostQuote(t),
      confirmationText: t(
        'expatLt.qnr.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'INCOMING_LT',
      PolicyDetails,
      checkoutIcon: imageTypeMapping.INCOMING_LT,
      additionalPolicyData: {
        regionOfPurchase: regionId,
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
