import { Region } from '@getpopsure/public-models';
import { REGION_INSURANCE_TYPE_MAP } from 'features/policiesDashboard/constants';
import { InsuranceTypes } from 'models/insurances/types';

export const isInsuranceTypeAvailable = (
  insuranceType: InsuranceTypes,
  region: Region,
  showExpatLt = false,
  showExpatEu = false
) => {
  if (insuranceType === 'INCOMING_LT' && !showExpatLt) {
    return false;
  }

  if (insuranceType === 'INCOMING_EU' && !showExpatEu) {
    return false;
  }

  const regionFilter = REGION_INSURANCE_TYPE_MAP[region] ?? [];

  return !!regionFilter.includes(insuranceType);
};
