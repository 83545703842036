import { QuestionnaireFormProps } from '@getpopsure/qnr-framework';
import {
  BottomOrRegularModal,
  Button,
  Card,
  Checkbox,
  SignaturePad,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import { useState } from 'react';
import { Trans, useSafeTranslation } from 'shared/i18n';

import penIcon from '../../assets/pen.svg';
import { ExpatLt } from '../../models';
import styles from './Signature.module.scss';

export const Signature = ({
  onSubmitValue,
}: QuestionnaireFormProps<ExpatLt>) => {
  const { t } = useSafeTranslation();
  const [value, setValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const [showDeclarationModal, setShowDeclarationModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  return (
    <div
      className={`p-container d-flex fd-column ai-center jc-center wmx10 ${styles.container}`}
    >
      <img src={penIcon} alt="family icon" />
      <h1 className="p-h1 mt16 ta-center">
        {t('expatLt.qnr.signature.title', 'Sign your application')}
      </h1>
      <p className="p-p mt8 mb16 ta-center">
        {t(
          'expatLt.qnr.signature.subtitle',
          'We need a clear signature to create'
        )}
      </p>
      <Card>
        <SignaturePad onChange={setValue} />
        <Checkbox
          bordered={false}
          wide
          classNames={{ container: 'mt24 w100' }}
          onChange={setCheckboxValues}
          options={{
            checkedTerms: {
              title: (
                <Trans i18nKey="expatLt.qnr.signature.terms">
                  I have read and understood the{' '}
                  <button
                    className={classNames(
                      'ds-interactive-component',
                      styles.inlineButton
                    )}
                    type="button"
                    onClick={() => setShowDeclarationModal(true)}
                  >
                    application declaration
                  </button>{' '}
                  and{' '}
                  <button
                    className={classNames(
                      'ds-interactive-component',
                      styles.inlineButton
                    )}
                    type="button"
                    onClick={() => setShowTermsModal(true)}
                  >
                    payment/jurisdiction terms
                  </button>
                  . By signing this, I confirm that I give my full consent.
                </Trans>
              ),
            },
          }}
          value={checkboxValues}
        />
      </Card>
      <div className={classNames(styles.button, 'd-flex ai-center mt24')}>
        <Button
          className={styles.button}
          onClick={() => onSubmitValue(value)}
          data-cy="signature-cta"
          disabled={!checkboxValues.includes('checkedTerms') || !value}
        >
          {t('expatLt.qnr.signature.cta', 'Continue')}
        </Button>
      </div>

      <BottomOrRegularModal
        isOpen={showDeclarationModal}
        onClose={() => setShowDeclarationModal(false)}
        title={t(
          'expatLt.qnr.declarationModal.title',
          'Application declaration'
        )}
      >
        <Markdown className="p24 pt0" paragraphClassName="p-p mb16">
          {t(
            'expatLt.qnr.declarationModal.body',
            'I hereby apply to be enrolled in the Plan together with the persons to be insured listed above. \n\nI/we declare that the information disclosed in this application form, is to the best of my/our knowledge and belief both accurate and complete. \n\nI/we have taken care not to make any misrepresentation in the disclosure of this information and understand that all information provided is relevant to the acceptance and assessment of this insurance. \n\nFor my benefit and protection, I have read the Policy Guide carefully and requested further information on any points I do not understand. I understand the Policy Guide to be part of any contract of insurance issued as a result of this Application. \n\nI agree that they will be binding on me and all eligible dependants included in my membership. \n\nI acknowledge on behalf of all the persons to be insured that benefits will not apply to treatment arising from any pre-existing conditions as more fully defined in the Policy Guide. \n\nBy signing the declaration below you are confirming that you understand the English language and the terms of cover where they have been provided to you in English. If you are unsure of any terms conditions or exclusions please seek assistance from your insurance adviser before you sign.'
          )}
        </Markdown>
      </BottomOrRegularModal>

      <BottomOrRegularModal
        isOpen={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        title={t('expatLt.qnr.termsModal.title', 'Payment/jurisdiction terms')}
      >
        <div className="p24 pt0">
          <h4 className="p-h4">
            {t('expatLt.qnr.declarationModal.title1', 'Payment terms')}
          </h4>
          <p className="p-p mb16">
            {t(
              'expatLt.qnr.declarationModal.body1',
              'I approve Feather (Popsure Deutschland GmbH) to collect premiums and remit them to the insurance provider.'
            )}
          </p>
          <h4 className="p-h4">
            {t('expatLt.qnr.declarationModal.title2', 'Jurisdiction terms')}
          </h4>
          <p className="p-p">
            {t(
              'expatLt.qnr.declarationModal.body2',
              'The insurance contract that is available to you is subject to the law and jurisdiction of the courts of Ireland (if your country of residence is Germany) and the jurisdiction of England and Wales for any other country. All terms are documented in English language.'
            )}
          </p>
        </div>
      </BottomOrRegularModal>
    </div>
  );
};
