import {
  COVERED_AREAS,
  CoveredArea,
  Deductible,
  DEDUCTIBLES,
  Plan,
  PLANS,
} from '@getpopsure/expat-lt-pricing-engine';
import {
  Address,
  Country,
  Gender,
  Region,
  regions,
} from '@getpopsure/public-models';
import { CheckoutInfo } from 'features/checkout/models';
import { Name } from 'models/user';
import { TFunction } from 'shared/i18n/utils';
import { ActionResponse } from 'shared/models/types';
import {
  ZAddressSchema,
  ZCountrySchema,
  ZNameSchema,
} from 'shared/models/validations';
import { BLOCKER, SCREEN } from 'SignupQuestionnaire';
import { z } from 'zod';

// TODO: Move this country-handling logic where it's centralized, translatable and reusable (f.e. i18n library)
// This will also allow us to handle country names that differ based on context ("Netherlands"/"The Netherlands")
export const regionToNameMapping: Record<Region, string> = {
  nl: 'Netherlands',
  se: 'Sweden',
  be: 'Belgium',
  it: 'Italy',
  at: 'Austria',
  mt: 'Malta',
  fi: 'Finland',
  cy: 'Cyprus',
  gr: 'Greece',
  ee: 'Estonia',
  pt: 'Portugal',
  lt: 'Lithuania',
  hr: 'Croatia',
  lv: 'Latvia',
  mc: 'Monaco',
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
};

export const isValidRegion = (region: string): region is Region => {
  return regions.includes(region as Region);
};

const Occupations = ['EMPLOYED', 'PROFESSIONAL_ATHLETE', 'OTHER'] as const;

export type Occupation = typeof Occupations[number];

export type QuotePlan = Plan;

export const mapPlanId: Record<QuotePlan, string> = {
  BASIC: 'Basic',
  PREMIUM: 'Premium',
};

export const occupationMapping = (
  t: TFunction
): Record<Occupation, string> => ({
  EMPLOYED: t('expatLt.qnr.occupationMapping.employed', 'Employed'),
  PROFESSIONAL_ATHLETE: t(
    'expatLt.qnr.occupationMapping.professionalAthlete',
    'Professional athlete'
  ),
  OTHER: t('expatLt.qnr.occupationMapping.otherOption', 'Other'),
});

export const genderMapping = (t: TFunction): Record<Gender, string> => ({
  MALE: t('expatLt.qnr.gender.optionMale', 'Male'),
  FEMALE: t('expatLt.qnr.gender.optionFemale', 'Female'),
  OTHER: t('expatLt.qnr.gender.optionOther', 'Gender non-conforming'),
});

export const ZQuote = z.object({
  id: z.string().optional(),
  dateOfBirth: z.string(),
  plan: z.enum(PLANS),
  deductible: z.enum(DEDUCTIBLES),
  germanRates: z.boolean(),
  coveredArea: z.enum(COVERED_AREAS),
});

export type Quote = z.TypeOf<typeof ZQuote>;

export type Quotes = {
  id?: string;
  dateOfBirth: string;
  plan: Plan;
  deductible: Deductible;
  germanRates: boolean;
  coveredArea: CoveredArea;
};

export type LegalGuardian = {
  name: Name;
  dateOfBirth: string;
  email: string;
};

export type CheckoutPolicyDetails = {
  regionOfPurchase: string;
  legalGuardian?: LegalGuardian;
  isMainPolicyTheLegalGuardian?: boolean;
};

export type CreateQuoteReturnType = {
  status: ActionResponse;
};

export type ExpatLt = {
  region: string;
  intro: SCREEN;

  dateOfBirth: string;
  ageIsBetween65And69Blocker: BLOCKER;
  ageIs70AndAboveBlocker: BLOCKER;

  occupation: Occupation;
  employedOccupationBlocker: BLOCKER;
  professionalAthleteOccupationBlocker: BLOCKER;

  quote: Quote;

  signupIntro: boolean;

  email: string;
  name: Name;
  gender: Gender;

  citizenship: Country[];
  lastPermanentResidency: Country;
  address: Address;

  startDate: string;

  legalGuardianName: Name;
  legalGuardianDateOfBirth: string;
  legalGuardianEmail: string;

  review: boolean;
  hasDownloadedDocs: boolean;
  downloadDocuments: boolean;

  checkoutInfo: CheckoutInfo<CheckoutPolicyDetails>;
  reviewCheckout: boolean;
  checkout: boolean;

  isPolicyHolder: boolean;

  addFamilyMembers: boolean;

  signature: string;

  dependent: Partial<ExpatLtDependent>;

  quoteId: string;
  questionnaireId: string;
};

export type ExpatLtDependent = Omit<
  ExpatLt,
  'addFamilyMembers' | 'email' | 'dependent'
> & {
  mainPolicyId: string;
  isMainPolicyTheLegalGuardian: boolean;
};

export const ZSubmittableExpatLtBaseQuestionnaire = z.object({
  region: z.string(),
  dateOfBirth: z.string(),
  occupation: z.enum(Occupations),
  name: ZNameSchema,
  gender: z.enum(['MALE', 'FEMALE', 'OTHER']),
  lastPermanentResidency: ZCountrySchema,
  address: ZAddressSchema,
  citizenship: z.array(ZCountrySchema),
  startDate: z.string(),
  legalGuardianName: z.optional(ZNameSchema),
  legalGuardianDateOfBirth: z.optional(z.string()),
  legalGuardianEmail: z.optional(z.string()),
  signature: z.string(),
});

export const ZSubmittableExpatLtQuestionnaire =
  ZSubmittableExpatLtBaseQuestionnaire.extend({
    email: z.string().email(),
    addFamilyMembers: z.optional(z.boolean()),
  }).transform(
    ({
      name,
      email,
      dateOfBirth,
      gender,
      startDate,
      citizenship,
      lastPermanentResidency,
      address,
      occupation,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      addFamilyMembers,
      signature,
    }) => {
      const hasLegalGuardian =
        legalGuardianName !== undefined &&
        legalGuardianDateOfBirth !== undefined &&
        legalGuardianEmail !== undefined;
      return {
        personalInfo: {
          name,
          email,
          dateOfBirth,
          gender,
          occupation,
          address,
          lastPermanentResidency,
          citizenship,
        },
        tariffInfo: {
          startDate,
          addFamilyMembers,
          signature,
          legalGuardian: hasLegalGuardian
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
        },
      };
    }
  );

export const ZSubmittableExpatLtDependentQuestionnaire =
  ZSubmittableExpatLtBaseQuestionnaire.extend({
    isMainPolicyTheLegalGuardian: z.optional(z.boolean()),
    mainPolicyId: z.string(),
  }).transform(
    ({
      name,
      dateOfBirth,
      gender,
      startDate,
      citizenship,
      lastPermanentResidency,
      address,
      occupation,
      legalGuardianName,
      legalGuardianDateOfBirth,
      legalGuardianEmail,
      isMainPolicyTheLegalGuardian,
      mainPolicyId,
      signature,
    }) => {
      const hasLegalGuardian =
        legalGuardianName !== undefined &&
        legalGuardianDateOfBirth !== undefined &&
        legalGuardianEmail !== undefined;
      return {
        personalInfo: {
          name,
          dateOfBirth,
          gender,
          occupation,
          lastPermanentResidency,
          address,
          citizenship,
        },
        tariffInfo: {
          startDate,
          isMainPolicyTheLegalGuardian,
          legalGuardian: hasLegalGuardian
            ? {
                name: legalGuardianName,
                dateOfBirth: legalGuardianDateOfBirth,
                email: legalGuardianEmail,
              }
            : undefined,
          signature,
        },
        mainPolicyId,
      };
    }
  );

export type SubmittableExpatLtQuestionnaire = z.infer<
  typeof ZSubmittableExpatLtQuestionnaire
>;

export type SubmittableExpatLtDependentQuestionnaire = z.infer<
  typeof ZSubmittableExpatLtDependentQuestionnaire
>;

export type QuotesAnswers = {
  region: string;
  dateOfBirth: string;
};

export type ExpatLtGroupIds = 'preQuote' | 'signup';

export type ExpatLtCancellationReasonsId =
  | 'RIGHT_OF_WITHDRAWAL'
  | 'FOUND_A_JOB'
  | 'LEFT_COUNTRY'
  | 'FOUND_ANOTHER_INSURANCE'
  | 'OTHER';
