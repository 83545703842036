import { website } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { TFunction } from 'shared/i18n/utils';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';

import { createDependentPostQuote } from '../actions';
import { PolicyDetails } from '../components/policyDetails';
import { Quote } from '../components/Quote';
import { Signature } from '../components/Signature/Signature';
import {
  ExpatLtDependent,
  ExpatLtGroupIds,
  genderMapping,
  occupationMapping,
} from '../models';

export const DATE_FORMAT = 'DD MMM YYYY';

export const ExpatLtDependentComponents = {
  QUOTE_PAGE: Quote,
  SIGNATURE: Signature,
} as const;

export type ExpatLtDependentQuestionnaire = SignupQuestionnaireType<
  ExpatLtDependent,
  ExpatLtGroupIds,
  typeof ExpatLtDependentComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  region: string,
  regionId: Region,
  continueFromAgeIsBetween65And69Blocker: () => void,
  continueFromEmployedOccupationBlocker: () => void
): ExpatLtDependentQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'expatLt.qnr.dependent.preQuote.intro.title',
        "Hey! Let's cover your loved one"
      ),
      subTitle: t(
        'expatLt.qnr.dependent.preQuote.intro.subTitle',
        'You can simply answer the following questions for them and their new policy will be added to your account.'
      ),
      iconTitle: t(
        'expatLt.qnr.dependent.preQuote.intro.iconTitle',
        'Long term expat health insurance'
      ),
    },
    screen: {
      continueButtonText: 'Get started',
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.dependent.preQuote.dateOfBirth.title',
        'When were they born?  '
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.dependent.dateOfBirth.validation.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'ageIs70AndAboveBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.dependent.preQuote.ageIs70AndAboveBlocker.title',
        'Unfortunately, we can’t sign them up'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.dependent.preQuote.ageIs70AndAboveBlocker.description',
        'Because they are over 70 years old, they aren’t eligible for long term expat health insurance.\n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatLt.qnr.dependent.preQuote.ageIs70AndAboveBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'ageIsBetween65And69Blocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.dependent.preQuote.ageIsBetween65And69Blocker.title',
        'Health screening needed'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.dependent.preQuote.ageIsBetween65And69Blocker.description',
        'As they are above 64 years of age, they will need to complete a health screening with a doctor to be able to sign-up for our expat health insurance.\n\nPlease get in touch with our insurance experts via the button below before continuing with your application.'
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromAgeIsBetween65And69Blocker,
          caption: t(
            'expatLt.qnr.dependent.preQuote.ageIsBetween65And69Blocker.button',
            'Continue application'
          ),
        },
        {
          type: 'href',
          href: website.support,
          variant: 'SECONDARY',
          caption: t(
            'expatLt.qnr.dependent.preQuote.ageIsBetween65And69Blocker.supportButton',
            'Get in touch'
          ),
        },
      ],
    },
  },
  {
    id: 'occupation',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: occupationMapping(t),
    },
    screen: {
      question: t('expatLt.qnr.dependent.preQuote.occupation.title', {
        defaultValue: 'What’s their main occupation in {{region}}?',
        region,
      }),
    },
    groupId: 'preQuote',
  },
  {
    id: 'professionalAthleteOccupationBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.dependent.preQuote.professionalAthleteOccupationBlocker.title',
        'Unfortunately, we can’t sign them up'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.dependent.preQuote.professionalAthleteOccupationBlocker.description',
        'As a professional athlete, we are not able to cover them with our expat health insurance.\n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatLt.qnr.dependent.preQuote.professionalAthleteOccupationBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedOccupationBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatLt.qnr.dependent.preQuote.employedOccupationBlocker.title',
        'Expat health insurance might not be right for them'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatLt.qnr.dependent.preQuote.employedOccupationBlocker.description',
        {
          defaultValue:
            'Expat health insurance is typically not accepted by employers in {{ region }}. It does not offer full health insurance coverage or cover pre-existing conditions. \n\nIf you have questions, please feel free to contact us.',
          region,
        }
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromEmployedOccupationBlocker,
          caption: t(
            'expatLt.qnr.dependent.preQuote.employedOccupationBlocker.button',
            'Continue application'
          ),
        },
        {
          type: 'href',
          href: website.support,
          variant: 'SECONDARY',
          caption: t(
            'expatLt.qnr.dependent.preQuote.employedOccupationBlocker.supportButton',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTE_PAGE',
    props: {},
    screen: {
      question: '',
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'expatLt.qnr.dependent.signup.signupIntro.title',
        "Great! Let's sign them up"
      ),
      subTitle: t(
        'expatLt.qnr.dependent.signup.signupIntro.subTitle',
        'Just 7 questions to your policy, it takes 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'expatLt.qnr.dependent.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.name.title',
        "What's their name?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.gender.title',
        'What is their gender?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'citizenship',
    required: true,
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.citizenship.title',
        'Which countries do they hold a passport from?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'lastPermanentResidency',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: t('expatLt.qnr.dependent.signup.lastPermanentResidency.title', {
        defaultValue:
          'What was their country of residence before arriving in {{ region }}?',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    type: 'ADDRESS',
    props: {},
    screen: {
      question: t('expatLt.qnr.dependent.signup.address.title', {
        defaultValue: 'What is their address in {{ region }}',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.startDate.title',
        'When would you like their coverage to start?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: -90,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.dependent.signup.startDate.errorMessage',
            'Coverage start date should not be more than 90 days in the future. Please adjust the start date.'
          ),
        },
      },
    ],
  },
  {
    id: 'isMainPolicyTheLegalGuardian',
    type: 'BOOLEAN',
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.isMainPolicyTheLegalGuardian',
        'Are you their legal guardian / parent?'
      ),
    },
    props: {},
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.legalGuardianName.title',
        "What's the name of their parent or legal guardian?"
      ),
      description: t(
        'expatLt.qnr.dependent.signup.legalGuardianName.description',
        "As they are under 18 years old, we need their parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatLt.qnr.dependent.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianEmail',
    type: 'EMAIL_BASIC',
    props: {},
    screen: {
      question: t(
        'expatLt.qnr.dependent.signup.legalGuardianEmail.email.title',
        "What's their email address?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'signature',
    required: true,
    type: 'SIGNATURE',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.plan.title',
            'Selected plan'
          ),
          value: { type: 'Strings', key: 'quote', keys: ['plan'] },
          path: 'quote',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.name.title',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.dateOfBirth.title',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.gender.title',
            'Gender'
          ),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.occupation.title',
            'Occupation'
          ),
          value: {
            type: 'String',
            valueMap: occupationMapping(t),
            key: 'occupation',
          },
          path: 'occupation',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.citizenship.title',
            'Citizenship'
          ),
          value: { type: 'Array', pick: ['name'], key: 'citizenship' },
          path: 'citizenship',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.lastPermanentResidency.title',
            'Last permanent residency'
          ),
          value: {
            type: 'Strings',
            key: 'lastPermanentResidency',
            keys: ['name'],
          },
          path: 'lastPermanentResidency',
        },
        {
          title: t('expatLt.qnr.signup.review.address.title', 'Address'),
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.startDate.title',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.isMainPolicyTheLegalGuardian.title',
            'The main policy holder is the parent / legal guardian'
          ),
          value: { type: 'Boolean', key: 'isMainPolicyTheLegalGuardian' },
          path: 'isMainPolicyTheLegalGuardian',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),
          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t(
            'expatLt.qnr.dependent.signup.review.legalGuardianEmail',
            "Parent / legal guardian's email"
          ),
          value: { type: 'String', key: 'legalGuardianEmail' },
          path: 'legalGuardianEmail',
        },
      ],
      verticalId: 'expatLt',
      insuranceType: 'INCOMING_LT',
      region: regionId,
      createPostQuote: () => createDependentPostQuote(t),
      confirmationText: t(
        'expatLt.qnr.dependent.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'INCOMING_LT',
      PolicyDetails,
      checkoutIcon: imageTypeMapping.INCOMING_LT,
      additionalPolicyData: {
        regionOfPurchase: regionId,
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
